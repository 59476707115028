.title {
  font-size: 50px;
  line-height: 100px;
}
.flip-clock-wrapper {
  width: auto;
  margin: 0;
  margin-top: 4px;
}

.flip-clock-wrapper ul {
  margin: 4px;
}

.flip-clock-divider {
  width: 12px;
}

.flip-clock-dot {
  left: 1px;
}

.flip-clock-divider.seconds .flip-clock-label {
  right: -89px;
}
.flip-clock-divider.hours .flip-clock-label {
  right: -82px;
}
